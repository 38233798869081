.description{
  padding-top: 50px + 100px;
  padding-bottom: 70px;
  background: url('#{$assets}/images/description.jpg') color(gray);
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;

  .col-50{
    color: color(white);

    @media screen and (max-width: 980px){
      width: 100%;
      text-align: center;
    }
  }
  h2,h3{
    margin-bottom: 20px;
    font-weight: 300;
  }
  h3{
    font-size: 30px;
    font-family: 'Arizonia', cursive;
  }
  h2{
    font-size: 28px;
    text-align: left;
    text-transform: none;
  }
  p{
    font-size: 16px;
  }
}
