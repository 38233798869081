.menu{
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  background: color(gray);
  height: 80px;
  font-size: 16px;
  z-index: 1200;

  &.menu-open nav{
    opacity: 1;
    visibility: visible;
  }

  .logo{
    padding: 15px 0;
    position: relative;
    float: left;
    height: 100%;
    width: auto;
  }
  &-mobile-trigger{
    width: 30px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    transform: translate(0,50%);
    display: none;

    @media screen and (max-width: 980px){
      display: block;
    }

    span{
      width: 100%;
      height: 4px;
      border-radius: 2px;
      display: block;
      position: absolute;
      background: color(red);

      &:nth-child(1){
        top: 0;
      }
      &:nth-child(2){
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3){
        top: 100%;
        transform: translateY(-100%);
      }
    }
  }
  nav{
    position: relative;
    float: right;

    @media screen and (max-width: 980px){
      position: absolute;
      width: 100%;
      margin: 0 -15px;
      height: 100vh;
      background: color(gray);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;

      ul{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
      }

      li{
        display: block;
        width: 100%;
        text-align: center;

        a{
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    li{
      display: inline-block;
      float: left;

      &:hover,
      &.active{
        background: color(red);

        a{
          color: color(white);
        }
      }

      a{
        line-height: 80px;
        padding: 0 20px;
        color: color(white);
        display: block;
      }
    }
  }
}
