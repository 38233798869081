//ASSETS - generate link to all assets
$assets: '../../assets';

//FONT STACK - remove Arial at will. It's here for example purposes.
$font-stack:
    (group: arial, id: regular, font: ("Arial", sans-serif), weight: 400, style: normal),
    (group: arial, id: bold, font: ("Arial", sans-serif), weight: 700, style: normal);


//COLOR STACK
$color-stack:
    (group: white, id: normal, color: #FFFFFF),
    (group: black, id: normal, color: #000000),
    (group: gray, id: normal, color: #131313),
    (group: red, id: normal, color: #ff0060),
    (group: text-gray, id: normal, color: #cecece);
