.contact{
  background: url('#{$assets}/images/contact.jpg');
  background-size: cover;
  background-position: center;
  padding-top: 80px;
  padding-bottom: 90px;

  @media screen and (max-width: 840px){

    .col-50{
      width: 100%;
      padding: 0;
      text-align: center;
    }
    .contact-form{
      margin-top: 40px;
    }
    .social{
      text-align: center;

      li{
        float: none;
      }
    }
  }

  h3{
    text-transform: uppercase;
    font-size: 24px;
    color: color(white);
  }
  p{
    font-size: 18px;
    color: color(white);
    margin-top: 35px;
  }

  &-information{
    margin-top: 140px;
    color: color(white);

    h3{
      font-size: 30px;
    }
    span{
      font-size: 20px;
      display: block;
    }
  }
  .social{
    margin-top: 20px;

    li a{
      margin: 0;
      margin-right: 15px;
      text-align: left;
    }
  }
  &-form{
    padding-left: 100px;
  }
}
