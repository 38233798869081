.btn{
  padding: 15px 60px;
  font-size: 1vw;
  text-transform: uppercase;

  @media screen and (max-width: 1400px){
    font-size: 14px;
  }

  &--round{
    border-radius: 50px;
  }
  &--red{
    color: color(white);
    background: color(red);
    transition: .3s;

    &:hover{
      background: darken(color(red), 5%);
    }
  }
}
