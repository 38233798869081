.numbers{
  padding-top: 70px;
  padding-bottom: 90px;

  @media screen and (max-width: 980px){
    text-align: center;
  }

  .percent span{
    display: inline-block;
    font-size: 60px;
    color: #fff;
    margin-bottom: 30px;
  }

  .col-33{
    min-width: 280px;
    margin-bottom: 20px;
  }

  i{
    width: 56px;
    height: 56px;
    display: inline-block;
    margin-bottom: 20px;
  }

  h2{
    font-size: 36px;
    color: color(white);
    padding: 0 15px;
    margin-bottom: 60px;
  }
  span{
    font-size: 14px;
    color: #55565b;
    display: block;

    &.number{
      font-size: 60px;
      color: white;
      margin-bottom: 30px;

      span{
        display: inline-block;
        font-size: 100%;
        color: white;
        margin-left: -10px;
      }
    }
  }

  .col-inner{
    padding: 30px;
    background: #202123;
    transition: background 0.3s;

    &:hover{
      background: color(red);

      span{
        color: color(white);
      }
    }
  }
}
