.offer{
  background: white;
  padding-top: 30px;
  padding-bottom: 0;

  @media screen and (max-width: 980px){
    .container{
      padding: 0;
    }
  }
  @media screen and (max-width: 840px){
    .base{
      width: 100%;
    }
  }

  .col-inner{
    margin: 0px;
    height: 195px;
  }

  &-single{
    vertical-align: bottom;

    @media screen and (max-width: 840px){
      width: 100%;
      text-align: center;

      &:hover{
        z-index: 2;
      }
      i{
        display: inline-block;
      }
    }
  }
  i{
    width: 60px;
    height: 60px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 50%;

    @media screen and (max-width: 840px){
      display: inline-block;
    }

    &:before{
      width: 80%;
      height: 80%;
    }
  }
  h3{
    font-size: 30px;
    color: color(black);
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p{
    font-size: 14px;
  }
  .base{
    padding: 30px;
    background: color(white);
    position: absolute;
    bottom: 0;
    overflow: hidden;

    p.more{
      padding-top: 20px;
    }

    img{
      height: 100%;
      width: auto;
      min-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
      opacity: 0;
    }
    p,h3,i{
      z-index: 2;
      position: relative;
    }

    &.hover{
      p,h3,i{
        z-index: 2;
        position: relative;
        color: white;
        border-color: white;
      }
      img{
        opacity: 1;
      }
    }
  }
}
