.feedback{
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .feedback-slider{
    border: 4px solid color(red);
    padding: 0 40px;
    padding-top: 65px;
    padding-bottom: 75px;
    text-align: right;

    @media screen and (max-width: 1200px){
      text-align: center;
    }

    &-navi{

      span{
        width: 44px;
        height: 65px;
        background: color(red);
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:hover{
          background: darken(color(red), 5%);
        }

        &:before{
          width: 36px;
          height: 36px;
        }

        &:nth-child(1){
          left: -43px;
          transform: rotateY(-180deg) translateY(-50%);
        }
        &:nth-child(2){
          right: -44px;
        }
      }
    }
    &-img{
      width: 335px;
      height: 460px;
      position: absolute;
      left: 40px;
      bottom: 40px;
      color: color(white);

      @media screen and (max-width: 1200px){
        left: inherit;
        position: relative;
        margin-top: -120px;
      }
      @media screen and (max-width: 580px){
        width: 100%;
      }

      li{
        overflow: hidden;
        position: relative;
      }
      .slides-number{
        padding: 15px;
        background: color(red);
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;

        span{
          font-size: 18px;
          display: inline-block;
        }
      }
    }
    &-description{
      text-align: left;
      padding-left: 20px;

      @media screen and (max-width: 1200px){
        padding: 0;
        text-align: center;
        width: 100%;
      }

      li{
        min-height: 300px;
      }

      h3{
        font-size: 30px;
        color: color(white);
        margin-bottom: 40px;
      }
      p{
        font-size: 16px;
        color: color(white);
        line-height: 1.6;
      }
    }
  }
}
