$curve: -20%;

@keyframes fly {
  0% {
    transform: translateY($curve);
  }
  50%{
    transform: translateY(0);
  }
  100%{
    transform: translateY($curve);
  }
}
