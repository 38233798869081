.cookies{
  bottom: 1vw;
  right: 1vw;
  border-radius: 15px;
  position: fixed;
  max-width: 500px;
  background: color(gray);
  padding: 25px;
  padding-right: 80px;
  opacity: 0;
  visibility: hidden;

  &.show{
    opacity: 1;
    visibility: visible;
  }

  i{
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;

    &:hover:before{
      background-position: right bottom;
    }
  }
  span{
    font-size: 12px;
    color: color(text-gray);

    a{
      color: color(red);
    }
  }
}
