.gallery{
  background: #f3f3f3;

  &-hover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    a{
      border: 3px solid color(red);
      padding: 15px 30px;
      color: color(white);
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-transform: uppercase;
    }
  }

  h2{
    color: color(gray);
    padding: 50px 0;
    margin-bottom: 0;
  }

  &-item{
    height: 25vw;
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 768px){
      width: 33.33%;
      height: 35vw;
    }

    @media screen and (max-width: 580px){
      width: 50%;
      height: 50vw;
    }
    @media screen and (max-width: 380px){
      width: 100%;
      height: 100vw;
    }

    &:hover{
      .gallery-hover{
        opacity: 1;
        visibility: visible;
      }
    }

    .col-inner{
      width: 100%;
      height: 100%;
      margin: 0;
    }

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: auto;
      transform: translate(-50%,-50%);
    }
  }
}
