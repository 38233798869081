.modal{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &-open{
    opacity: 1;
    visibility: visible;
  }
  &-content{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 640px;
    padding: 0 60px;
  }
  &-navi{

    span{
      width: 40px;
      height: 40px;
      display: block;
      position: absolute;
      top: 50%;
      cursor: pointer;

      &:nth-child(1){
        left: 0;
        transform: rotateY(-180deg);
      }
      &:nth-child(2){
        right: 0;
      }
    }
  }
  &-slider{
    width: 100%;

    img{
      width: 100%;
      height: auto;
    }
  }
  &-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(red);
    opacity: .95;
    cursor: pointer;
  }
}
