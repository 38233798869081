.team{
  background: #f3f3f3;
  padding: 70px 0;

  @media screen and (max-width: 1200px){
    text-align: center;

    .col-33{
      min-width: 350px;
    }
  }

  h2{
    color: color(gray);
    margin-bottom: 0;
  }

  .container > p{
    font-size: 16px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 10px;
  }

  .col-inner:hover{
    .team-member{
      transform: rotateY(-90deg);
      transition: transform .2s 0s;

      &--hover{
        transform: rotateY(0);
        transition: transform .2s .2s;
      }
    }
  }

  .team-member{
    position: relative;
    transform: rotateY(0);
    transition: transform .2s .2s;

    &--hover{
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: color(white);
      padding: 55px 30px;
      padding-bottom: 100px;
      z-index: 1;
      text-align: center;
      box-shadow: 0px 0px 9px rgba(0,0,0,0.2);
      transform: rotateY(-90deg);
      transition: transform .2s 0s;

      .team-img{
        width: 120px;
        height: 120px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 50%;
        padding: 5px;
        overflow: hidden;
        display: inline-block;

        img{
          width: 100%;
          height: auto;
          border: 1px solid rgba(0,0,0,0.2);
          border-radius: 50%;
        }
      }
      h3{
        font-size: 24px;
        font-weight: bold;
        margin-top: 35px;
        padding-bottom: 50px;
        text-transform: uppercase;

        span,small{
          display: block;
        }

        small{
          font-size: 60%;
          font-weight: normal;
        }
      }
      p{
        font-size: 14px;
        margin-bottom: 15px;
      }
      .social{
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
    }

    img{
      width: 100%;
      height: auto;
    }
    h3{
      font-size: 24px;
      margin-top: 30px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 50px;
      text-transform: uppercase;

      span,small{
        display: block;
      }

      small{
        font-size: 50%;
        font-weight: normal;
      }
    }
  }
}
