.icon{
  position: relative;

  &:before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('#{$assets}/images/icon-sprite.png');
    background-size: 300%;
    transform: translate(-50%,-50%);
  }

  &--scroll:before{
    background-position: top right;
  }
  &--close:before{
    background-position: right 75%;
  }
  &--camera:before{
    background-position: center 25%;
  }
  &--camera.white:before{
    background-position: center top;
  }
  &--light:before{
    background-position: left 25%;
  }
  &--light.white:before{
    background-position: left top;
  }
  &--arrow-left:before,
  &--arrow-right:before{
    background-position: center right;
  }
  &--fb:before{
    background-position: center bottom;
  }
  &--fb.white:before{
    background-position: center 75%;
  }
  &--yt:before{
    background-position: left bottom;
  }
  &--yt.white:before{
    background-position: left 75%;
  }
  &--calender:before{
    background-position: left 50%;
  }
  &--ring:before{
    background-position: right 25%;
  }
  &--couple:before{
    background-position: center 50%;
  }
}
