footer{
  background: #f3f3f3;
  font-size: 14px;
  padding: 10px;
  overflow: hidden;

  .container{
    overflow: hidden;
  }

  .copyright{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .execution{
    float: right;

    span,img{
      display: inline-block;
      vertical-align: middle;
    }
    span{
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 768px){
    .copyright,
    .execution{
      width: 100%;
      display: block;
      float: none;
      padding: 0;
      position: relative;
      transform: none;
      top: inherit;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
