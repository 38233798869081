header{
  position: fixed;
  width: 100%;
  height: 100vh;
  text-align: center;
  background: url('#{$assets}/images/header.jpg') color(gray);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  color: color(white);

  .container{
    position: absolute;
    top: 54vh;
    left: 50%;
    transform: translate(-50%,-30%);
  }

  .logo{
    width: 18vw;
    max-width: 350px;
    min-width: 180px;
    height: auto;
  }

  h1{
    font-size: 2.6vw;
    margin-bottom: 1vw;
    margin-top: .5vw;

    @media screen and (max-width: 1400px){
      font-size: 36px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    @media screen and (max-width: 980px){
      font-size: 24px;
    }
  }
  h2{
    text-transform: uppercase;
    font-size: 1.5vw;
    margin-bottom: 1vw;

    @media screen and (max-width: 1400px){
      font-size: 18px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 980px){
      font-size: 14px;
    }
  }

  .scroll-down{
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translate(-50%,0);
    text-align: center;
    width: 3vw;
    min-width: 38px;
    cursor: pointer;

    i{
      width: 3vw;
      min-width: 38px;
      height: 3vw;
      min-height: 38px;
      display: inline-block;
      margin-bottom: 5px;
      animation: fly 1s infinite;
    }
    span{
      display: block;
      text-transform: uppercase;
      font-size: 0.7vw;

      @media screen and (max-width: 1400px){
        font-size: 10px;
      }
    }
  }
}
