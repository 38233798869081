.container{
  width: 1200px;
  padding: 0 15px;
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 1200px){
    width: 100%;
  }
}

.col-25,
.col-33,
.col-50,
.col-66{
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  position: relative;

  .col-inner{
    margin: 0 15px;
    position: relative;
  }
}
.col-25{
  width: 25%;
}
.col-33{
  width: 33.33%;
}
.col-50{
  width: 50%;
}
.col-66{
  width: 66.66%;

  @media screen and (max-width: 980px){
    width: 80%;
  }
}
