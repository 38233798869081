::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
   color: color(white);
}

form{
  margin-top: 35px;

  input,
  textarea{
    border: 2px solid color(white);
    width: 100%;
    display: block;
    background: transparent;
    margin-bottom: 15px;
    padding: 15px;
    font-size: 18px;
    color: color(white);

    &.error{
      border-color: color(red);
    }
  }
  textarea{
    height: 190px;
  }
  .submit{
    margin-top: 25px;
    display: block;
    cursor: pointer;
    text-align: center;
  }
}
