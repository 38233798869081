body{
  background: color(gray);
  line-height: 1.2;
  font-size: 0;
}

.page-content{
  top: 100vh;
  position: relative;
}

a{
  color: color(red);
}

section{

  h2{
    font-size: 36px;
    color: white;
    text-align: center;
    margin-bottom: 140px;
    text-transform: uppercase;
  }
}

.social{
  position: relative;
  text-align: left;

  li{
    display: inline-block;

    a{
      width: 50px;
      height: 50px;
      display: block;
      margin: 0 10px;
      border: 3px solid color(red);
      position: relative;
    }
  }
  i{
    width: 80%;
    height: 80%;
    display: block;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
  }
}
